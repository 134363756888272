jQuery(function ($) {

    $('.feature .feature-carousel').each(function () {
        var $this = $(this);
        $this.slick({
            accessibility: true,
            dots: true,
            infinite: true,
            slidesToShow: 1,
            focusOnChange: true,
            // autoplaySpeed: 5000,
            autoplay: false,
            prevArrow: $(this).parents('.feature-card-wrapper').find('.slick-prev'),
            nextArrow: $(this).parents('.feature-card-wrapper').find('.slick-next'),
            appendDots: $(this).parents('.feature-card-wrapper').find('.feature-navigation-dots'),
        });

        // $this.on('init', function (event, slick, currentSlide, nextSlide) {
        //     $this.find('.slick-slide[aria-hidden="false"] a').attr('tabindex', -1);
        //     $this.find('.slick-slide[aria-hidden="false"] a').attr('tabindex', 0);
        // });
        // $this.on('afterChange', function (event, slick, currentSlide, nextSlide) {
        //     $this.find('.slick-slide[aria-hidden="false"] a').attr('tabindex', -1);
        //     $this.find('.slick-slide[aria-hidden="true"] a').attr('tabindex', 0);
        // });
    });
});